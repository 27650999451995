<template>
  <div class="bindbankcard-box">
      <div class="top-bar">
         银行卡绑定
      </div>
      <div class="main-box">
          <div class="panel" v-if="step==0">
              <van-form @submit="handleBankSubmit">
                <van-field v-if="type=='1'" name="持卡人姓名" v-model="from.bankAccountName" label="持卡人姓名" :rules="[{ required: true, message: '' }]" placeholder="请输入持卡人姓名"/>
                <van-field v-if="type=='2'" name="企业名称" v-model="from.bankAccountName" label="企业名称" :rules="[{ required: true, message: '' }]" placeholder="请输入企业名称"/>
                <van-field name="开户行" readonly  @click="showBankPicker = true" is-link v-model="from.bankName" label="开户行" :rules="[{ required: true, message: '' }]" placeholder="请输入开户行"/>
                <van-popup v-model="showBankPicker" round position="bottom">
                    <!-- <div class="popup-bar">
                        <div class="popup-btn" @click="showBankPicker=false">取消</div>
                        <div class="tip-txt">选择银行</div>
                        <div class="popup-btn" @click="handleChangeBank">确认</div>
                    </div> -->
                    <van-picker
                        title="选择银行"
                        confirm-button-text="确认"
                        cancel-button-text="取消"
                        :show-toolbar="true"
                        :columns="bankLists"
                        @cancel="showBankPicker = false"
                        @confirm="onBankConfirm"
                        :columns-field-names="customFieldName"
                    />
                </van-popup>
                <van-field name="银行账号"  v-model="from.bankCard" label="银行账号" :rules="[{ required: true, message: '' }]" placeholder="请输入银行账号"/>
                <!-- <van-field name="联行号"  v-model="from.unionPayNo" label="联行号" :rules="[{ required: true, message: '' }]" placeholder="请输入联行号"/> -->
                <van-field label-width="120" name="银行预留手机号"  v-model="from.mobile" label="银行预留手机号" :rules="[{ required: true, message: '' },{ validator: asyncMobileValidator, message: '手机号不合法' }]" placeholder="请输入银行预留手机号"/>
                <van-field v-if="type=='1'"  name="身份证号"  v-model="from.xibAccountId" label="身份证号" :rules="[{ required: true, message: '' }]" placeholder="请输入身份证号"/>
                <van-field v-if="type=='2'" name="营业执照编号"  v-model="from.xibAccountId" label="营业执照编号" :rules="[{ required: true, message: '' }]" placeholder="请输入营业执照编号"/>
                <div class="btn">
                    <van-button round block type="info" native-type="submit">下一步</van-button>
                </div>
              </van-form>
          </div>
          <div class="panel" v-if="step==1">
              <van-form @submit="handleInfoSubmit">
                <!-- <van-field name="手机号码" v-model="from.mobile" disabled  :rules="[{ required: true, message: '' },{ validator: asyncMobileValidator, message: '手机号不合法' }]" label="手机号码" placeholder="请输入手机号码"/> -->
                <van-field center v-model="from.smsCode" :rules="[{ required: true, message: '' }]" clearable label="短信验证码" placeholder="请输入短信验证码">
                    <!-- <template #button>
                        <van-button size="small" type="primary" :disabled="!from.mobile" @click="sendCode">
                            <van-count-down v-if="mobileCodeTimer>0" ref="countDown" millisecond  :time="mobileCodeTimer" :auto-start="false"
                                format="ss秒后可重新获取" @finish="finish" />
                            <span v-else >发送验证码</span>
                        </van-button>
                    </template> -->
                </van-field>
                <van-field v-if="type=='2'" name="打款金额"  v-model="from.transAmt" label="打款金额" :rules="[{ required: true, message: '' }]" placeholder="请输入打款金额"/>
                <div class="btn" style="margin-top:5vh;">
                    <van-button round block type="info" native-type="submit">下一步</van-button>
                </div>
                <div class="btn">
                    <van-button round block  @click="step=0">上一步</van-button>
                </div>
              </van-form>
          </div>
          <div class="result" v-if="step==2">
              <div class="tips" v-if="result.code==0">
                  <h2 class="title">绑定成功</h2>
                  <img src="../../assets/icon/success.svg" alt="">
                  <p class="txt">卡号：{{result.bankCard}}</p>
              </div>
              <div class="tips" v-else>
                  <h2 class="title">绑定失败</h2>
                  <img src="../../assets/icon/error.svg" alt="">
                  <p class="txt">{{result.msg}}</p>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import {bankList,addWithdrawAccount,bindCardVerify} from '@/api/api';
import { Dialog } from 'vant';
export default {
    data() {
        return {
            step:0,
            from:{},
            mobileCodeTimer:0,
            acctType:1,
            result:{},
            bankLists:[],
            showBankPicker:false,
            type:""
        }
    },
    created(){
        this.type=this.$route.query.type;
        if(this.type=='1'){
            this.from['xibAccountId']=this.$route.query.idCard;
            this.from['bankAccountName']=this.$route.query.realName;
        }
        if(this.type=='2'){
           this.from['xibAccountId']=this.$route.query.regNo
           this.from['bankAccountName']=this.$route.query.entName;
        }
        bankList().then(res=>{
            for(var item in res){
              res[item].text=res[item].bankName;
              res[item].value=res[item].bankName;
            }
            this.bankLists = res;
        })
    },
    methods:{
        asyncMobileValidator(val){
            return /^(?:(?:\+|00)86)?1(?:3[\d]|4[5-79]|5[0-35-9]|6[5-7]|7[0-8]|8[\d]|9[189])\d{8}$/.test(val);
        },
        handleBankSubmit(){
            let _this =this;
            addWithdrawAccount(this.from).then(res=>{
                _this.from['orderNo']=res.orderNo
                _this.step=1;
            }).catch(err=>{
                _this.result=err;
                _this.step=2;
            })
        },
        onBankConfirm(val){
            this.from['bankName']=val.bankName;
            this.from['unionPayNo']=val.lineNo;
            this.from={...this.from};
            this.showBankPicker=false;
        },
        handleInfoSubmit(){
            let _this =this;
            bindCardVerify(this.from).then(res=>{
                _this.result=res;
                _this.result['code']=0;
                _this.step=2;
            }).catch(err=>{
                _this.result=err;
                _this.step=2;
            })
        },
        sendCode(){
            if(!/^(?:(?:\+|00)86)?1(?:3[\d]|4[5-79]|5[0-35-9]|6[5-7]|7[0-8]|8[\d]|9[189])\d{8}$/.test(this.from.mobile)){
                Dialog.alert({
                    title: '提示',
                    message: '手机号不正确',
                    theme: 'round-button',
                }).then(() => {});
                return;
            }
            if(this.mobileCodeTimer<=0){
                this.mobileCodeTimer=60*1000;
                this.$nextTick(()=>{
                    this.$refs.countDown.start();
                    //DTO调用发送验证码

                })
            }
        },
        finish(){
          this.mobileCodeTimer=0;
        }
    }
}
</script>
<style scoped>
.bindbankcard-box{
    width: 100%;
    height: calc(100vh);
    margin: 0px;
    padding: 0px;
}
.bindbankcard-box .top-bar{
    line-height: 4;
    font-size: 40px;
    padding: 10px;
    font-weight: 550;
}
::v-deep .van-cell__title.van-field__label,
::v-deep .van-cell__value.van-field__value{
    font-size: 30px;
    line-height: 3;
}
::v-deep .van-uploader__upload{
    width: 30vw;
    height:30vw;
}
::v-deep .van-icon.van-icon-photograph.van-uploader__upload-icon{
    font-size: 70px;
}
::v-deep .van-button{
    font-size: 30px;
    height: 80px;
}
::v-deep .van-field__error-message{
    font-size: 30px;
}
::v-deep .van-count-down{
     font-size: 30px;
}
::v-deep .van-radio-group.van-radio-group--horizontal{
    height: 100px;
}
::v-deep .van-radio-group.van-radio-group--horizontal .van-radio__icon{
    font-size: 40px;
}
::v-deep .van-radio-group.van-radio-group--horizontal .van-radio__label{
    font-size: 30px;
}
::v-deep .van-cell.van-cell--clickable.van-field .van-icon.van-icon-arrow.van-cell__right-icon{
    line-height: 3;
}
.btn{
  margin: 2vh 20px;  
}
.result,.panel{
    margin-top:2vh;
    padding: 20px;
}
.tips .title{
    font-size: 35px;
}
.tips .txt{
    font-size: 30px;
}
.popup-bar{
    height: 80px;
    width: 100vw;
    display: flex;
}
.popup-bar div{
    display: block;
    line-height: 80px;
    width: 33.33%;
    font-size: 30px;
}
.popup-bar div:first-child{
    text-align: left;
    padding-left: 20px;
    color: cornflowerblue;
}
.popup-bar div:last-child{
    text-align: right;
    padding-right: 20px;
    color: cornflowerblue;
}
</style>